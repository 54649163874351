export const socialIcons = [
  {
    name: "instagram",
    url: "https://www.instagram.com/bananabrewery",
  },
  {
    name: "tiktok",
    url: "https://www.tiktok.com/@bananabrewery",
  },
  {
    name: "x",
    url: "https://x.com/bananabrewery",
  },
  {
    name: "youtube",
    url: "https://www.youtube.com/@BananaBrewery",
  },
  {
    name: "facebook",
    url: "https://www.facebook.com/bananabrewery",
  },
  {
    name: "twitch",
    url: "https://www.twitch.tv/bananabrewery",
  },
  {
    name: "email",
    url: "mailto:hello@bananabrewery.es",
  },
];
